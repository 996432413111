import { AppDataContext } from "@src/components/AppDataContext";
import { STATIC_URL } from "@src/components/partials/landing-pages/constants";
import DownloadModal, { DownloadModalProps } from "@src/components/partials/sl-resources/DownloadModal";
import EventsSection, { EventsSectionProps } from "@src/components/partials/sl-resources/EventsSection";
import HeroSection, { HeroSectionProps } from "@src/components/partials/sl-resources/HeroSection";
import SchoolsSection, { SchoolsSectionProps } from "@src/components/partials/sl-resources/SchoolsSection";
import SchoolWideSection, { SchoolWideSectionProps } from "@src/components/partials/sl-resources/SchoolwideSection";
import SpotlightSection, { SpotlightSectionProps } from "@src/components/partials/sl-resources/SpotlightsSection";
import SEO from "@src/components/SEO";
import { logEvent } from "@src/utils/logClient";
import useSearchParams from "@src/utils/useSearchParams";
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect, useState } from "react";

const SlResources = () => {
  const {
    directus: { page_sl_resources },
  } = useStaticQuery(
    graphql`
      {
        directus {
          page_sl_resources {
            seo_image {
              id
              filename_disk
            }
            hero_image {
              id
              filename_disk
            }
            hero_links
            events_event_list {
              upcoming
              name
              title
              description
              avatar {
                id
                filename_disk
              }
              date
              live_url
              recording_url
            }
            spotlights_downloads {
              photo {
                id
                filename_disk
              }
              cta_url
            }
            schools_image {
              id
              filename_disk
            }
            schools_cta_url
            schoolwide_image {
              id
              filename_disk
            }
            schoolwide_cta_url
            q2_options
          }
        }
      }
    `,
  );

  const {
    seo_image,
    hero_image,
    hero_links,
    events_event_list,
    schools_image,
    schools_cta_url,
    schoolwide_image,
    schoolwide_cta_url,
    spotlights_downloads,
    q2_options,
  } = page_sl_resources;
  const [showModal, setShowModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [seenModal, setSeenModal] = useState(false);
  const [eventId, setEventId] = useState("");
  const appData = useContext(AppDataContext);

  const isLoggedIn = !seenModal && !!appData.data.userData;
  const closeModal = () => setShowModal(false);
  const openModal = (url: string, title: string) => {
    setShowModal(true);
    setSeenModal(true);
    logEvent({
      eventName: "web.external_page.sl_resources.resource_modal.show",
      eventValue: title,
    });
    setDownloadUrl(url);
    setEventId(id);
  };

  const [success] = useSearchParams("success");

  const sortEvents = (a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();

    return dateA - dateB;
  };
  const isBrowser = typeof window !== "undefined";
  const params = isBrowser ? Object.fromEntries(new URLSearchParams(window.location.search).entries()) : {};

  const events = events_event_list.reduce(
    (acc, cur, index) => {
      const events = acc;
      const event: any = {
        avatar: STATIC_URL + cur.avatar.filename_disk,
        title: `directus.page_sl_resources_events_${index + 1}.title`,
      };
      if (cur.upcoming) {
        (event.date = cur.date),
          (event.description = `directus.page_sl_resources_events_${index + 1}.description`),
          (event.url = cur.live_url),
          events.upcoming.push(event);
      } else if (cur.recording_url) {
        (event.name = cur.name), (event.url = cur.recording_url), events.past.push(event);
      }
      return events;
    },
    { upcoming: [], past: [] },
  );

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.sl_resources.page_view",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const heroAnchorEventNames = [
    "web.external_page.sl_resources.hero_link.learn_with_leaders.tap",
    "web.external_page.sl_resources.hero_link.school_spotlights.tap",
    "web.external_page.sl_resources.hero_link.go_schoolwide.tap",
  ];

  const heroSectionProps: HeroSectionProps = {
    image: STATIC_URL + hero_image.filename_disk,
    heading: "directus.page_sl_resources.hero_heading",
    links: hero_links.map(({ id }, index: number) => ({
      label: `directus.page_sl_resources.hero_links.label_${index + 1}`,
      href: "#" + id,
      eventName: heroAnchorEventNames[index],
    })),
  };

  const eventsSectionProps: EventsSectionProps = {
    id: hero_links[0].id,
    heading: "directus.page_sl_resources.events_heading",
    tagline: "directus.page_sl_resources.events_description",
    upcomingEvents: events.upcoming.sort(sortEvents),
    carouselHeading: "directus.page_sl_resources.events_carousel_title",
    pastEvents: events.past.sort(sortEvents),
    isLoggedIn,
    openModal,
  };

  const spotlightSectionProps: SpotlightSectionProps = {
    id: hero_links[1].id,
    heading: "directus.page_sl_resources.spotlight_heading",
    tagline: "directus.page_sl_resources.spotlight_tagline",
    spotlights: spotlights_downloads.map(({ photo, cta_url }, index: number) => ({
      image: STATIC_URL + photo.filename_disk,
      title: `directus.page_sl_resources_spotlights_${index + 1}.title`,
      cta: {
        url: cta_url,
        label: `directus.page_sl_resources_spotlights_${index + 1}.cta_label`,
      },
    })),
    isLoggedIn,
    openModal,
  };

  const schoolsSectionProps: SchoolsSectionProps = {
    image: STATIC_URL + schools_image.filename_disk,
    heading1: "directus.page_sl_resources.schools_heading_first_line",
    heading2: "directus.page_sl_resources.schools_heading_second_line",
    cta: {
      url: schools_cta_url,
      label: "directus.page_sl_resources.schools_cta_label",
    },
  };

  const schoolWideSectionProps: SchoolWideSectionProps = {
    id: hero_links[2].id,
    heading: "directus.page_sl_resources.schoolwide_title",
    description: "directus.page_sl_resources.schoolwide_tagline",
    card: {
      image: STATIC_URL + schoolwide_image.filename_disk,
      title: "directus.page_sl_resources.schoolwide_kit_title",
      description: "directus.page_sl_resources.schoolwide_kit_description",
      cta: {
        url: schoolwide_cta_url,
        label: "directus.page_sl_resources.schoolwide_cta_label",
      },
    },
    isLoggedIn,
    openModal,
  };

  const downloadModalProps: DownloadModalProps = {
    closeModal,
    success: typeof success === "string",
    title: "directus.page_sl_resources.title",
    description: "directus.page_sl_resources.description",
    q1: {
      text: "directus.page_sl_resources.q1_text",
      placeholder: ["directus.page_sl_resources.q1_placeholder", "directus.page_sl_resources.q1_placeholder_b"],
    },
    q2: {
      text: "directus.page_sl_resources.q2_text",
      options: q2_options.map((_, index: number) => `directus.page_sl_resources.q2_options.text_${index + 1}`),
    },
    q3: {
      text: "directus.page_sl_resources.q3_text",
      placeholder: "directus.page_sl_resources.q3_placeholder",
    },
    q4: {
      text: "What's your school name?*",
      placeholder: "Classdojo Fundamental School",
    },
    q5: {
      text: "Address line one",
    },
    q6: {
      text: "Address line two",
    },
    q7: {
      text: "City",
      placeholder: "San Francisco",
    },
    q8: {
      text: "State",
      placeholder: "CA",
    },
    cta: {
      label: "directus.page_sl_resources.submit_label",
      url: downloadUrl,
      id: eventId,
    },
  };

  return (
    <>
      <SEO
        title="directus.page_sl_resources.seo_title"
        description="directus.page_sl_resources.seo_description"
        image={`STATIC_URL + ${seo_image.filename_disk}`}
      />
      <HeroSection {...heroSectionProps} />
      <EventsSection {...eventsSectionProps} />
      <SpotlightSection {...spotlightSectionProps} />
      <SchoolsSection {...schoolsSectionProps} />
      <SchoolWideSection {...schoolWideSectionProps} />
      {showModal && <DownloadModal {...downloadModalProps} />}
    </>
  );
};

export default SlResources;
